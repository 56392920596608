












































































































































































import { Component, Vue } from 'vue-property-decorator';
import { waitFor, generateRandomClass } from '@/services/util';
import WButton from '@/components/Button.vue';
import Preloader from '@/components/Preloader.vue';
import wRadio from '@/components/Radio.vue';
import { sendMail } from '@/services/api';

@Component({
  components: {
    WButton,
    wRadio,
    Preloader,
  },
})
export default class wUniqueFrom extends Vue {
  formData: any = {
    package: {},
    name: '',
    email: '',
    message: '',
  };

  privateQuestions: any[] = [
    {
      title: 'What is the occasion?',
      question: 'What is the occasion?',
      key: 'occasion',
      number: 1,
      answers: [
        {
          label: 'Birthday',
          name: 'birthday',
        },
        {
          label: 'Bachelor Party',
          name: 'bachelorParty',
        },
        {
          label: 'Graduation',
          name: 'graduation',
        },
        {
          label: 'Anniversary',
          name: 'anniversary',
        },
        {
          label: 'Other',
          name: 'other',
        },
      ],
    },
    {
      title: 'Where is the event to be located?',
      question: 'Where is the event to be located?',
      key: 'location',
      number: 2,
      answers: [
        {
          label: 'Home',
          name: 'home',
        },
        {
          label: 'Local Hostelry',
          name: 'localHostelry',
        },
        {
          label: 'Public Hall / Room',
          name: 'publicHall',
        },
        {
          label: 'Other',
          name: 'other',
        },
      ],
    },
    {
      title: 'How many participants?',
      question: 'How many participants?',
      key: 'participants',
      number: 3,
      answers: [
        {
          label: 'min 4-10',
          name: '4to10',
        },
        {
          label: '11-20',
          name: '11to20',
        },
        {
          label: '21-30',
          name: '21to30',
        },
        {
          label: '31-50 max',
          name: '31to50',
        },
      ],
    },
    {
      title: 'What is the budget per person?',
      question: 'What is the budget per person?',
      key: 'budget',
      number: 4,
      answers: [
        {
          label: '50&euro;',
          name: '50',
        },
        {
          label: '75&euro;',
          name: '75',
        },
        {
          label: '100&euro;',
          name: '100',
        },
        {
          label: 'over 100&euro;',
          name: 'over100',
        },
      ],
    },
    {
      title: 'What level of whisky experience<br>in general?',
      question: 'What level of whisky experience in general?',
      key: 'experience',
      number: 5,
      answers: [
        {
          label: 'No experience / beginner',
          name: 'beginner',
        },
        {
          label: 'Some experience / preferred whisky only',
          name: 'some',
        },
        {
          label: 'Quite experienced / good knowledge',
          name: 'quite',
        },
        {
          label: 'Some or all of above',
          name: 'fewOfAbove',
        },
      ],
    },
    {
      title: 'Would you like to select<br>a theme for your journey?',
      question: 'Would you like to select a theme for your journey?',
      key: 'theme',
      number: 6,
      answers: [
        {
          label: 'yes',
          name: 'yes',
        },
        {
          label: 'no',
          name: 'no',
        },
      ],
    },
    {
      title: 'Choose a theme or specify your own:',
      question: 'Choose a theme or specify your own:',
      key: 'theme_type',
      number: 7,
      answers: [
        {
          label: 'Seasonal (e.g. Halloween, Autumn, Christmas)',
          name: 'seasonal',
        },
        {
          label: 'Game of Thrones Selection',
          name: 'got',
        },
        {
          label: 'Johnny Walker Selection',
          name: 'johnnyWalker',
        },
        {
          label: 'Famous Grouse Selection',
          name: 'famousGrouse',
        },
        {
          label: 'Single Distillery or Regional Experience',
          name: 'singleOrRegional',
        },
        {
          label: 'Age Statement Whiskies (e.g. 10, 12, 25 yo.)',
          name: 'ageStatement',
        },
        {
          label: 'Other',
          name: 'other',
        },
      ],
    },
    {
      title: 'What type of whisky would you<br>like to experience?',
      question: 'What type of whisky would you like to experience?',
      key: 'type',
      number: 7,
      answers: [
        {
          label: 'Smoke',
          name: 'smoke',
        },
        {
          label: 'Peaty',
          name: 'peaty',
        },
        {
          label: 'Fruity',
          name: 'fruity',
        },
        {
          label: 'Floral',
          name: 'floral',
        },
        {
          label: 'Some or all of above',
          name: 'fewOfAbove',
        },
      ],
    },
  ];

  submitting: boolean = false;
  error: boolean = false;
  step: number = 0;
  type: string = '';

  formSubmitted: boolean = false;
  corporateFormSent: boolean = false;
  formWithError: boolean = false;
  formSpecificClass: string = '';

  showForm: boolean = false;

  timeout: any;

  goToNextStep(key: string, value: string) {
    if (key) {
      if (key === 'type') {
        this.type = value;
      }
      this.formData.package[key] = value;
    }
    this.step += 1;

    console.log(this.privateQuestions);
  }

  goBack() {
    this.step -= 1;
  }

  setRadioValue() {
    this.step += 1;
  }

  onFocus() {
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
    } else {
      this.$store.dispatch('setInputStatus', true);
    }
  }

  onBlur() {
    this.timeout = setTimeout(() => {
      this.$store.dispatch('setInputStatus', false);
      this.timeout = null;
    }, 250);
  }

  // eslint-disable-next-line
  onShow() {
    const form = document.querySelector('.unique-form .form-wrapper');
    if (form) {
      form.classList.add('shown');
    }
  }

  async sendForm(e: any) {
    if (e) {
      e.preventDefault();
    }

    this.formSubmitted = true;

    if (!this.formData.name || !this.formData.email) {
      return;
    }

    let mailHTML: string = '';
    let name: string = '';

    name = 'Unique journey';
    this.privateQuestions.forEach((question: any) => {
      if (question.answer) {
        mailHTML += `<div>${question.question}</div><div style="margin-bottom: 15px;">${question.answer}</div>`;
      }
    });

    this.step += 1;
    this.submitting = true;

    let error: boolean = false;
    try {
      await sendMail({
        email: this.formData.email,
        name: this.formData.name,
        subject: `${name} journey requested`,
        message: this.formData.message,
        info: mailHTML,
      });
    } catch (err) {
      error = true;
    }

    if (!error) {
      await waitFor(1000);

      this.submitting = false;
    } else {
      this.error = true;
    }
  }
}
