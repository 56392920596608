




























import { Component, Vue } from 'vue-property-decorator';
import Footer from '@/components/Footer.vue';
import ScrollBlock from '@/components/ScrollBlock.vue';
import WCookies from '@/components/Cookies.vue';
import WAgeRestriction from '@/components/AgeRestriction.vue';
import ShowMore from '@/components/ShowMore.vue';

@Component({
  name: 'App',
  components: {
    Footer,
    ScrollBlock,
    WCookies,
    WAgeRestriction,
    ShowMore,
  },
})
export default class App extends Vue {
}
