






































































































































import { Component, Vue } from 'vue-property-decorator';
import ScrollDownIcon from '@/components/ScrollDownIcon.vue';
import { eventBus } from '@/services/eventBus';

@Component({
  name: 'Imprint',
  components: {
    ScrollDownIcon,
  },
})
export default class Imprint extends Vue {
  isTouch: boolean = false;

  mounted() {
    this.$nextTick(async () => {
      if (this.$store.getters.isFirstPageLoaded) {
        eventBus.$emit('endPageTransition');
        eventBus.$on('pageTransitionEnded', this.onPageTransitionEnd);
      } else {
        await this.$store.dispatch('setFirstPageLoad');
        this.initPage();
      }
    });

    this.isTouch = 'ontouchstart' in window;
  }

  // eslint-disable-next-line
  initPage() {
    eventBus.$emit('enableScroll');
  }

  onPageTransitionEnd() {
    this.initPage();
    eventBus.$off('pageTransitionEnded', this.onPageTransitionEnd);
  }
}
