




































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import WButton from '@/components/Button.vue';
import AnimatedText from '@/components/AnimatedText.vue';
import { eventBus } from '@/services/eventBus';
import ScrollDownIcon from '@/components/ScrollDownIcon.vue';

@Component({
  name: 'Home',
  components: {
    WButton,
    AnimatedText,
    ScrollDownIcon,
  },
})
export default class Home extends Vue {
  imageStyles: any = null;
  animationBlocks: any = {
    desktop: {
      showSecondBlock: false,
      showThirdBlock: false,
      showFourthBlock: false,
    },
    mobile: {
      showSecondBlock: false,
      showThirdBlock: false,
      showFourthBlock: false,
      showFifthBlock: false,
      showSixthBlock: false,
    },
  };
  showLogoAnimation: boolean = true;
  textAnimFinished: boolean = false;
  $refs: any;

  mounted() {
    const right = this.$refs.right as HTMLElement;
    const animatedParts = document.querySelectorAll('.animatedPart');
    const mainParts = document.querySelectorAll('.mainPart');
    const logo = document.getElementById('logo');
    const titles = document.querySelectorAll('.title');
    const underConstruction = document.getElementById('underConstruction');
    const logoWrapper = document.getElementById('logoWrapper');
    const learnMore = this.$refs.learnMore as HTMLElement;

    this.showLogoAnimation = this.$store.getters.isFirstPageLoaded;

    this.$nextTick(async () => {
      if (this.$store.getters.isFirstPageLoaded) {
        eventBus.$emit('endPageTransition');
        eventBus.$on('pageTransitionEnded', this.onPageTransitionEnd);
      } else {
        await this.$store.dispatch('setFirstPageLoad');

        right.addEventListener('transitionend', () => {
          if (right.classList.contains('step-2')) {
            mainParts.forEach((mainPart) => {
              mainPart.classList.add('step-3');
            });
          } else if (right.classList.contains('step-1')) {
            animatedParts.forEach((animatedPart) => {
              animatedPart.classList.add('step-2');
            });
          }
        });

        mainParts[0].addEventListener('transitionend', () => {
          if (mainParts[0].classList.contains('step-3')) {
            if (logo) {
              titles.forEach((title) => {
                title.classList.add('step-4');
              });
              logo.classList.add('step-4');
            }
          }
        });

        titles[0].addEventListener('transitionend', () => {
          if (logo && logo.classList.contains('step-4')) {
            if (logoWrapper && logo) {
              setTimeout(() => {
                logoWrapper.classList.add('step-5');
                logo.classList.add('step-5');
              }, 750);
            }
            if (underConstruction) {
              setTimeout(() => {
                underConstruction.classList.add('step-5');
              }, 1250);
            }
          }
        });

        if (logo) {
          logo.addEventListener('transitionend', () => {
            if (logo.classList.contains('step-5')) {
              learnMore.classList.add('shown');

              setTimeout(() => {
                eventBus.$emit('enableScroll');
              }, 500);
            }
          });
        }

        // Fede in glass parts
        setTimeout(() => {
          if (animatedParts) {
            animatedParts.forEach((animatedPart) => {
              animatedPart.classList.add('step-1');
            });
          }
        }, 300);
      }
    });

    window.addEventListener('resize', this.onResize, true);
    // Listening eventBus
    eventBus.$on('activateAnimation', this.onAnimateBlock);
    eventBus.$on('activateHalfBlockAnimation', this.onHalfBlockAnimation);
  }

  destroyed() {
    eventBus.$off('activateAnimation', this.onAnimateBlock);
    eventBus.$off('activateHalfBlockAnimation', this.onHalfBlockAnimation);
    window.removeEventListener('resize', this.onResize, true);
  }

  // eslint-disable-next-line
  initPage() {
    eventBus.$emit('enableScroll');
  }

  onPageTransitionEnd() {
    this.initPage();
    eventBus.$off('pageTransitionEnded', this.onPageTransitionEnd);
  }
  // Packages animation
  // eslint-disable-next-line
  showPackagesAnimation() {
    const ornaments = document.querySelectorAll('.ornament-part');
    const parts = [1, 2, 3, 4];

    setTimeout(() => {
      ornaments.forEach((ornament) => {
        ornament.classList.add('animated');
      });

      setTimeout(() => {
        parts.forEach((part, index) => {
          const pointers = document.querySelectorAll(`.package-pointer.part-${part}`);
          const packageBlock = document.querySelector(`.package.part-${part}`);

          if (pointers) {
            pointers.forEach((pointer) => {
              pointer.classList.add('shown');
            });
            setTimeout(() => {
              if (packageBlock) {
                packageBlock.classList.add('shown');
              }
            }, 1000);
          }
        });
      }, 2000);
    }, 0);
  }

  onAnimateBlock(payload: any) {
    const { page, block } = payload;

    if (page === 'home') {
      if (window.innerWidth >= 768) {
        if (block === 1 && !this.animationBlocks.desktop.showSecondBlock) {
          this.animationBlocks.desktop.showSecondBlock = true;
          this.animationBlocks.mobile.showSecondBlock = true;
          this.animationBlocks.mobile.showThirdBlock = true;

          this.$refs.firstAnimatedText.onShow();

          this.$refs.animatedImage.classList.add('activated');
          this.$refs.textBlock.classList.add('active');
          setTimeout(() => {
            this.textAnimFinished = true;
          }, 1000);
        } else if (block === 2 && !this.animationBlocks.desktop.showThirdBlock) {
          this.animationBlocks.desktop.showThirdBlock = true;
          this.animationBlocks.mobile.showFourthBlock = true;
          this.animationBlocks.mobile.showFifthBlock = true;

          this.$refs.imagesWrapper.classList.add('activated');

          setTimeout(() => {
            this.$refs.secondAnimatedText.onShow();
          }, 2000);
        } else if (block === 3 && !this.animationBlocks.desktop.showFourthBlock) {
          this.animationBlocks.desktop.showFourthBlock = true;
          this.animationBlocks.mobile.showSixthBlock = true;

          this.$refs.animatedTitle.classList.add('activated');
          setTimeout(() => {
            this.showPackagesAnimation();
          }, 500);
        }
      } else if (window.innerWidth < 768) {
        if (block === 1 && !this.animationBlocks.mobile.showSecondBlock) {
          this.animationBlocks.mobile.showSecondBlock = true;

          this.$refs.firstAnimatedText.onShow();
        } else if (block === 2 && !this.animationBlocks.mobile.showThirdBlock) {
          this.animationBlocks.mobile.showThirdBlock = true;
          this.animationBlocks.desktop.showSecondBlock = true;

          this.$refs.animatedImage.classList.add('activated');
          this.$refs.textBlock.classList.add('active');
          setTimeout(() => {
            this.textAnimFinished = true;
          }, 1000);
        } else if (block === 3 && !this.animationBlocks.mobile.showFourthBlock) {
          this.animationBlocks.mobile.showFourthBlock = true;

          this.$refs.imagesWrapper.classList.add('activated');
        } else if (block === 4 && !this.animationBlocks.mobile.showFifthBlock) {
          this.animationBlocks.mobile.showFifthBlock = true;
          this.animationBlocks.desktop.showThirdBlock = true;

          this.$refs.secondAnimatedText.onShow();
        } else if (block === 5 && !this.animationBlocks.mobile.showSixthBlock) {
          this.animationBlocks.mobile.showSixthBlock = true;
          this.animationBlocks.desktop.showFourthBlock = true;

          this.$refs.animatedTitle.classList.add('activated');
          setTimeout(() => {
            this.showPackagesAnimation();
          }, 500);
        }
      }
    }
  }

  onHalfBlockAnimation(payload: any) {
    const { page, block, down } = payload;
    const animatedImageContainer = document.getElementById('animatedImageContainer');
    const animatedImage = document.getElementById('animatedImage');

    if (
      (
        window.innerWidth >= 768 && page === 'home' && block === 1
      ) || (
        window.innerWidth < 768 && page === 'home' && block === 2
      )
    ) {
      if (down) {
        if (animatedImageContainer && animatedImage) {
          let translateX = 'translateX(calc((var(--vh, 1vh) * 100) / 3 * 2 + 10px - 100vw))';
          if (window.innerWidth / window.innerHeight <= 1.2) {
            if (window.innerWidth >= 768) {
              const blockContent = document.getElementById('blockContent');
              if (blockContent) {
                translateX = `translateX(${blockContent.clientWidth * -1}px)`;
              }
            } else {
              translateX = 'translateX(0)';
            }
          }
          this.imageStyles = {
            transform: `${translateX} translateY(100%) scale(${animatedImageContainer.clientHeight / animatedImage.clientHeight})`,
          };
        }
      } else {
        this.imageStyles = null;
      }
    }
  }

  onResize() {
    if (window.innerWidth >= 768) {
      if (this.animationBlocks.mobile.showSecondBlock && !this.animationBlocks.desktop.showSecondBlock) {
        this.animationBlocks.mobile.showThirdBlock = true;
        this.animationBlocks.desktop.showSecondBlock = true;

        this.$refs.animatedImage.classList.add('activated');
        this.$refs.textBlock.classList.add('active');
        setTimeout(() => {
          this.textAnimFinished = true;
        }, 1000);
      } else if (this.animationBlocks.mobile.showFourthBlock && !this.animationBlocks.desktop.showThirdBlock) {
        this.animationBlocks.mobile.showFifthBlock = true;
        this.animationBlocks.desktop.showThirdBlock = true;

        this.$refs.secondAnimatedText.onShow();
      }
    }

    if (this.imageStyles) {
      setTimeout(() => {
        const animatedImageContainer = document.getElementById('animatedImageContainer');
        const animatedImage = document.getElementById('animatedImage');

        if (animatedImageContainer && animatedImage) {
          let translateX = 'translateX(calc((var(--vh, 1vh) * 100) / 3 * 2 + 10px - 100vw))';
          if (window.innerWidth / window.innerHeight <= 1.2) {
            if (window.innerWidth >= 768) {
              const blockContent = document.getElementById('blockContent');
              if (blockContent) {
                translateX = `translateX(${blockContent.clientWidth * -1}px)`;
              }
            } else {
              translateX = 'translateX(0)';
            }
          }
          this.imageStyles = {
            transform: `${translateX} translateY(100%) scale(${animatedImageContainer.clientHeight / animatedImage.clientHeight})`,
          };
        }
      }, 1000);
    }
  }

  // eslint-disable-next-line
  learnMore() {
    const windowHeight = window.innerHeight;
    eventBus.$emit('scrollTo', windowHeight);
  }
}
