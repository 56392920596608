













import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'ShowMore',
})
export default class ShowMore extends Vue {
  close() {
    console.log(1);
    this.$store.commit('setShowMoreData', '');
  }
}
