















































import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';

@Component({
  name: 'Footer',
  computed: {
    ...mapGetters(['isScrollEnabled']),
  },
})
export default class Menu extends Vue {
  isOpen: boolean = false;
  pageTransitionActive: boolean = false;

  toggleMenu() {
    this.isOpen = !this.isOpen;
    this.$store.commit('setScrollStatus', !this.isOpen);
  }

  activateLink(e: any) {
    if (!e.target.classList.contains('router-link-exact-active')) {
      this.pageTransitionActive = true;
      setTimeout(() => {
        this.toggleMenu();
        setTimeout(() => {
          this.pageTransitionActive = false;
        }, 750);
      }, 750);
    } else {
      this.toggleMenu();
    }
  }
}
