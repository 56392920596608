











import { Component, Vue } from 'vue-property-decorator';
import WButton from '@/components/Button.vue';

@Component({
  name: 'WAgeRestriction',
  components: {
    WButton,
  },
})
export default class WAgeRestriction extends Vue {
  showAlert: boolean = false;

  mounted() {
    this.showAlert = !window.localStorage.getItem('ageAccepted');
  }

  handleClick(e: any) {
    this.showAlert = false;
    window.localStorage.setItem('ageAccepted', 'yes');
  }
}
