










import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  name: 'ScrollDownIcon',
})
export default class ScrollDownIcon extends Vue {
}
