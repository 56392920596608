import Vue from 'vue';
import Router from 'vue-router';
import Home from './views/Home.vue';
import About from './views/About.vue';
import Packages from './views/Packages.vue';
import Blog from './views/Blog.vue';
import Imprint from './views/Imprint.vue';
import Contacts from './views/Contacts.vue';
import { eventBus } from '@/services/eventBus';
import { waitFor } from '@/services/util';
import store from './store';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      meta: {
        title: 'Unique tasting experience | Whisky Class',
        metaTags: [
          {
            name: 'description',
            content: 'My vocation is teaching, but my passion is whisky and I would like to bring to you a \'Taste of Scotland\'. In the Whisky Class you will learn the methods of tasting and drinking the whisky so that you fully understand the flavours and aromas. With some background knowledge of the distilleries and their production processes, you could soon be on your way to becoming an expert. Hopefully, you too will also develop a passion for Scottish Whisky.',
          },
        ],
      },
      component: Home,
    },
    {
      path: '/about',
      name: 'about',
      meta: {
        title: 'About | Whisky Class',
        metaTags: [
          {
            name: 'description',
            content: 'As a teacher for over thirty years, I have had many opportunities to travel and see the world. Prior to settling in Carinthia, I lived with my family in a rural location overlooking the famous Loch Ness in the Highlands of Scotland. Over sixteen years, my interest and passion for Scottish whisky grew and evolved.',
          },
        ],
      },
      component: About,
    },
    {
      path: '/packages',
      name: 'packages',
      meta: {
        title: 'Journeys | Whisky Class',
        metaTags: [
          {
            name: 'description',
            content: 'Customised tasting sessions - a journey not to be forgotten. Select you journey.',
          },
        ],
      },
      component: Packages,
    },
    {
      path: '/blog',
      name: 'blog',
      meta: {
        title: 'Blog | Whisky Class',
        metaTags: [
          {
            name: 'description',
            content: 'All about Whisky Class',
          },
        ],
      },
      component: Blog,
    },
    {
      path: '/imprint',
      name: 'imprint',
      meta: {
        title: 'Imprint | Whisky Class',
      },
      component: Imprint,
    },
    {
      path: '/contacts',
      name: 'contacts',
      meta: {
        title: 'Contacts | Whisky Class',
      },
      component: Contacts,
    },
    {
      path: '*',
      redirect: '/',
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  if (to.meta) {
    document.title = to.meta.title ? to.meta.title : 'Whisky Class';
  } else {
    document.title = 'Whisky Class';
  }

  if (store.getters.isFirstPageLoaded) {
    eventBus.$emit('startPageTransition');
    await waitFor(800);
    next();
  } else {
    next();
  }
});

export default router;
