























import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  name: 'wRadio',
})
export default class wRadio extends Vue {
  @Prop({ required: true })
  data: any;
  showTooltip: any = {
    item0: false,
    item1: false,
  };

  get wRadioValue() {
    return this.data.value;
  }

  set wRadioValue(value) {
    this.$emit('change', value);
  }

  showMobileDescription(description: string) {
    if ('ontouchstart' in window) {
      this.$store.commit('setShowMoreData', description);
    }
  }
}
