














































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import AnimatedText from '@/components/AnimatedText.vue';
import wPackageForm from '@/components/PackageForm.vue';
import wUniqueForm from '@/components/UniqueForm.vue';
import wCorporateForm from '@/components/CorporateForm.vue';
import WButton from '@/components/Button.vue';
import ScrollDownIcon from '@/components/ScrollDownIcon.vue';
import { eventBus } from '@/services/eventBus';
import { getPackages } from '@/services/api';

@Component({
  name: 'Packages',
  components: {
    AnimatedText,
    wPackageForm,
    wUniqueForm,
    wCorporateForm,
    WButton,
    ScrollDownIcon,
  },
})
export default class Packages extends Vue {
  showFirstBlock: boolean = false;
  showSecondBlock: boolean = false;
  showThirdBlock: boolean = false;
  showFourthBlock: boolean = false;
  showFifthBlock: boolean = false;
  showSixthBlock: boolean = false;

  $refs: any;
  packageBlock: any;

  forms: any = {
    classic: [],
    quality: [],
  };

  // forms: any = {
  //   classic: [
  //     {
  //       name: 'highland',
  //       radio: [
  //         {
  //           label: 'Ardmore Legacy',
  //           name: 'ardmoreLegacy',
  //         },
  //         {
  //           label: 'Glen Garioch',
  //           name: 'glenGarioch',
  //         },
  //       ],
  //       value: '',
  //     },
  //     {
  //       name: 'speyside',
  //       radio: [
  //         {
  //           label: 'Glenfiddich 12',
  //           name: 'glenfiddich12',
  //         },
  //         {
  //           label: 'Aberlour 12',
  //           name: 'aberlour12',
  //         },
  //       ],
  //       value: '',
  //     },
  //     {
  //       name: 'islay',
  //       radio: [
  //         {
  //           label: 'Ardbeg 10',
  //           name: 'ardbeg10',
  //         },
  //         {
  //           label: 'Bowmore 10',
  //           name: 'bowmore10',
  //         },
  //       ],
  //       value: '',
  //     },
  //     {
  //       name: 'islands',
  //       radio: [
  //         {
  //           label: 'Highland Park 12',
  //           name: 'highlandPark12',
  //         },
  //         {
  //           label: 'Talisker 10',
  //           name: 'talisker10',
  //         },
  //       ],
  //       value: '',
  //     },
  //     {
  //       name: 'campbeltown',
  //       radio: [
  //         {
  //           label: 'Glen Scotia Double Cask',
  //           name: 'glenScotiaDoubleCask',
  //         },
  //         {
  //           label: 'Springbank 10',
  //           name: 'springbank10',
  //         },
  //       ],
  //       value: '',
  //     },
  //     {
  //       name: 'lowland',
  //       radio: [
  //         {
  //           label: 'Auchentoshan American Oak',
  //           name: 'auchentoshanAmericanOak',
  //         },
  //         {
  //           label: 'Glenkinchie',
  //           name: 'glenkinchie',
  //         },
  //       ],
  //       value: '',
  //     },
  //   ],
  //   quality: [
  //     {
  //       name: 'highland',
  //       radio: [
  //         {
  //           label: 'Dalmore 15',
  //           name: 'dalmore15',
  //         },
  //         {
  //           label: 'Clynelish 14',
  //           name: 'clynelish14',
  //         },
  //       ],
  //       value: '',
  //     },
  //     {
  //       name: 'speyside',
  //       radio: [
  //         {
  //           label: 'Glenfiddich 15 Solera Reserve',
  //           name: 'glenfiddich15SoleraReserve',
  //         },
  //         {
  //           label: 'Balvenie 14 Caribbean Cask',
  //           name: 'balvenie14CaribbeanCask',
  //         },
  //       ],
  //       value: '',
  //     },
  //     {
  //       name: 'islay',
  //       radio: [
  //         {
  //           label: 'Lagavulin 16',
  //           name: 'lagavulin16',
  //         },
  //         {
  //           label: 'Bowmore 15',
  //           name: 'bowmore15',
  //         },
  //       ],
  //       value: '',
  //     },
  //     {
  //       name: 'islands',
  //       radio: [
  //         {
  //           label: 'Talisker Dark Storm',
  //           name: 'taliskerDarkStorm ',
  //         },
  //         {
  //           label: 'Jura 16 Diurachs\' Own',
  //           name: 'jura16DiurachsOwn',
  //         },
  //       ],
  //       value: '',
  //     },
  //     {
  //       name: 'campbeltown',
  //       radio: [
  //         {
  //           label: 'Glen Scotia 15',
  //           name: 'glenScotia15',
  //         },
  //         {
  //           label: 'Glen Scotia Victoriana',
  //           name: 'glenScotiaVictoriana',
  //         },
  //       ],
  //       value: '',
  //     },
  //     {
  //       name: 'lowland',
  //       radio: [
  //         {
  //           label: 'Auchentoshan 12',
  //           name: 'auchentoshan12',
  //         },
  //         {
  //           label: 'Ailsa Bay Sweet Smoke',
  //           name: 'ailsaBaySweetSmoke',
  //         },
  //       ],
  //       value: '',
  //     },
  //   ],
  //   ultimate: [
  //     {
  //       name: 'highland',
  //       radio: [
  //         {
  //           label: 'Tomatin 18',
  //           name: 'tomatin18',
  //         },
  //         {
  //           label: 'Dalmore Cigar Malt',
  //           name: 'dalmoreCigarMalt',
  //         },
  //       ],
  //       value: '',
  //     },
  //     {
  //       name: 'speyside',
  //       radio: [
  //         {
  //           label: 'Glenfiddich 21',
  //           name: 'glenfiddich21',
  //         },
  //         {
  //           label: 'Glenfarclas 21',
  //           name: 'glenfarclas21',
  //         },
  //       ],
  //       value: '',
  //     },
  //     {
  //       name: 'islay',
  //       radio: [
  //         {
  //           label: 'Lagavulin Distillery Edition',
  //           name: 'lagavulinDistilleryEdition',
  //         },
  //         {
  //           label: 'Bowmore 18',
  //           name: 'bowmore18',
  //         },
  //       ],
  //       value: '',
  //     },
  //     {
  //       name: 'islands',
  //       radio: [
  //         {
  //           label: 'Highland Park Dark Origins',
  //           name: 'highlandParkDarkOrigins ',
  //         },
  //         {
  //           label: 'Ledaig 18 (Sherry Wood Finish)',
  //           name: 'ledaig18Finish',
  //         },
  //       ],
  //       value: '',
  //     },
  //     {
  //       name: 'campbeltown',
  //       radio: [
  //         {
  //           label: 'Glen Scotia 18',
  //           name: 'glenScotia18',
  //         },
  //         {
  //           label: 'Springbank 18',
  //           name: 'springbank18',
  //         },
  //       ],
  //       value: '',
  //     },
  //     {
  //       name: 'lowland',
  //       radio: [
  //         {
  //           label: 'Glenkinchie Distillery Edition',
  //           name: 'glenkinchieDistilleryEdition',
  //         },
  //         {
  //           label: 'North British Distillery 30 (Single Grain Whisky)',
  //           name: 'northBritishDistillery30',
  //         },
  //       ],
  //       value: '',
  //     },
  //   ],
  // };


  async mounted() {
    let { hash }: any = this.$route;

    if (hash) {
      hash = hash.slice(1);
      this.packageBlock = document.querySelector(`.${hash}-package`);
    }

    this.forms = (await getPackages()).data.data;

    this.$nextTick(async () => {
      if (this.$store.getters.isFirstPageLoaded) {
        eventBus.$emit('endPageTransition');
        eventBus.$on('pageTransitionEnded', this.onPageTransitionEnd);
      } else {
        await this.$store.dispatch('setFirstPageLoad');
        this.initPage();
      }

      // Listening eventBus
      eventBus.$on('activateAnimation', this.onAnimateBlock);
    });
  }

  initPage() {
    eventBus.$emit('enableScroll');
    if (this.packageBlock) {
      eventBus.$emit('scrollTo', this.packageBlock.offsetTop);
      eventBus.$off('pageTransitionEnded', this.onPageTransitionEnd);
    }
  }

  onPageTransitionEnd() {
    this.initPage();
  }

  onAnimateBlock(payload: any) {
    const { page, block } = payload;
    const SHOW_INTERVAL: number = 100;

    if (page === 'packages') {
      if (block === 1 && !this.showFirstBlock) {
        this.showFirstBlock = true;

        const aboutBlock = document.querySelector('.about-block');
        if (aboutBlock) {
          aboutBlock.classList.add('shown');
        }
      } else if (block === 2 && !this.showSecondBlock) {
        const parts = document.querySelectorAll('.classic-package .description-part');

        this.showSecondBlock = true;

        parts.forEach((part: any, index: number) => {
          setTimeout(() => {
            part.classList.add('shown');
          }, SHOW_INTERVAL * index);

          if (index === parts.length - 1) {
            setTimeout(() => {
              this.$refs.secondAnimatedBlock.onShow();
            }, SHOW_INTERVAL * index + 500);
          }
        });
      } else if (block === 3 && !this.showThirdBlock) {
        const parts = document.querySelectorAll('.quality-package .description-part');

        this.showThirdBlock = true;

        parts.forEach((part: any, index: number) => {
          setTimeout(() => {
            part.classList.add('shown');
          }, SHOW_INTERVAL * index);

          if (index === parts.length - 1) {
            setTimeout(() => {
              this.$refs.thirdAnimatedBlock.onShow();
            }, SHOW_INTERVAL * index + 500);
          }
        });
      } else if (block === 4 && !this.showFourthBlock) {
        const parts = document.querySelectorAll('.ultimate-package .description-part');

        this.showFourthBlock = true;

        parts.forEach((part: any, index: number) => {
          setTimeout(() => {
            part.classList.add('shown');
          }, SHOW_INTERVAL * index);

          if (index === parts.length - 1) {
            setTimeout(() => {
              this.$refs.fourthAnimatedBlock.onShow();
            }, SHOW_INTERVAL * index + 500);
          }
        });
      } else if (block === 5 && !this.showFifthBlock) {
        const parts = document.querySelectorAll('.unique-package .description-part');

        this.showFifthBlock = true;

        parts.forEach((part: any, index: number) => {
          setTimeout(() => {
            part.classList.add('shown');
          }, SHOW_INTERVAL * index);

          if (index === parts.length - 1) {
            setTimeout(() => {
              this.$refs.fifthAnimatedBlock.onShow();
            }, SHOW_INTERVAL * index + 200);
          }
        });
      } else if (block === 6 && !this.showSixthBlock) {
        const parts = document.querySelectorAll('.corporate-package .description-part');

        this.showSixthBlock = true;

        parts.forEach((part: any, index: number) => {
          setTimeout(() => {
            part.classList.add('shown');
          }, SHOW_INTERVAL * index);

          if (index === parts.length - 1) {
            setTimeout(() => {
              this.$refs.sixthAnimatedBlock.onShow();
            }, SHOW_INTERVAL * index + 200);
          }
        });
      }
    }
  }

  @Watch('$route')
  onRouteChange() {
    let { hash }: any = this.$route;

    if (hash) {
      hash = hash.slice(1);
      this.packageBlock = document.querySelector(`.${hash}-package`);
    }
    eventBus.$emit('endPageTransition');
    eventBus.$on('pageTransitionEnded', this.onPageTransitionEnd);
  }
}
