































import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  name: 'Map',
})
export default class Map extends Vue {
  @Prop({ default: '' })
  activeRegion: string;
  @Prop({ default: 663 })
  width: number;
  @Prop({ default: 1000 })
  height: number;

  get styles() {
    return {
      height: `${this.height}px`,
      width: `${this.width}px`,
    };
  }

  get activeArea() {
    return `active-${this.activeRegion}`;
  }
}
