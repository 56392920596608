/**
 * Generate unique UID
 * @returns {String} GUID
 */
export const generateRandomClass = (prefix: string = 'xx') => `${prefix}-xxxx-xxxx`
  .replace(/[xy]/g, (c) => {
    const random = Math.random() * 16 | 0; //eslint-disable-line
    const v = c === 'x' ? random : random & 0x3 | 0x8; //eslint-disable-line
    return v.toString(16);
  });

export const waitFor = (delay = 1000) => new Promise(resolve => setTimeout(resolve, delay));
