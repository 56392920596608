import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    firstPageLoaded: false,
    scrollEnabled: false,
    inputActive: false,
    showMoreData: '',
  },
  mutations: {
    firstPageLoaded(state) {
      state.firstPageLoaded = true;
    },
    setScrollStatus(state: any, scrollStatus: boolean) {
      state.scrollEnabled = scrollStatus;
    },
    setInputStatus(state: any, inputStatus: boolean) {
      state.inputActive = inputStatus;
    },
    setShowMoreData(state: any, showMoreData: string) {
      state.showMoreData = showMoreData;
    },
  },
  actions: {
    setFirstPageLoad() {
      this.commit('firstPageLoaded');
    },
    setScrollStatus(state: any, scrollStatus: boolean) {
      this.commit('setScrollStatus', scrollStatus);
    },
    setInputStatus(state: any, inputStatus: boolean) {
      this.commit('setInputStatus', inputStatus);
    },
    setShowMoreData(state: any, showMoreData: string) {
      this.commit('setShowMoreData', showMoreData);
    },
  },
  getters: {
    isFirstPageLoaded(state) {
      return state.firstPageLoaded;
    },
    isScrollEnabled(state) {
      return state.scrollEnabled;
    },
    isInputActive(state) {
      return state.inputActive;
    },
    showMoreData(state) {
      return state.showMoreData;
    },
  },
});
