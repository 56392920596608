





























































































































































































import { Component, Vue } from 'vue-property-decorator';
import ScrollDownIcon from '@/components/ScrollDownIcon.vue';
import { eventBus } from '@/services/eventBus';
import WButton from '@/components/Button.vue';

@Component({
  name: 'Blog',
  components: {
    ScrollDownIcon,
    WButton,
  },
})
export default class Blog extends Vue {
  isTouch: boolean = false;

  mounted() {
    this.$nextTick(async () => {
      if (this.$store.getters.isFirstPageLoaded) {
        eventBus.$emit('endPageTransition');
        eventBus.$on('pageTransitionEnded', this.onPageTransitionEnd);
      } else {
        await this.$store.dispatch('setFirstPageLoad');
        this.initPage();
      }
    });

    this.isTouch = 'ontouchstart' in window;

    // Listening eventBus
    eventBus.$on('activateAnimation', this.onAnimateBlock);
  }

  destroyed() {
    eventBus.$off('activateAnimation', this.onAnimateBlock);
  }

  // eslint-disable-next-line
  initPage() {
    eventBus.$emit('enableScroll');
  }

  onPageTransitionEnd() {
    this.initPage();
    eventBus.$off('pageTransitionEnded', this.onPageTransitionEnd);
  }

  // eslint-disable-next-line
  onAnimateBlock(payload: any) {
    const { page, block } = payload;

    if (page === 'blog') {
      const articles = document.getElementsByClassName('article-block');
      if (articles[block - 1]) {
        articles[block - 1].classList.add('activated');
      }
    }
  }

  setShowMore(block: string) {
    const data: any = {};
    data.bavarian = `
          Testimonials
          <br><br>
          "We will not forget the wonderful and eventful evening with Stuart. A new world opened up for us. Stuart's extraordinary
          background knowledge immersed us in the history of Scottish whiskies and individual distilleries. We were able to taste and
          enjoy wonderful whiskies in a cheerful and relaxed atmosphere. Our favourite was the Longmorn, but we may discover more.
          We look forward to a follow-up. Thank you very much."
          <span class="text-right">Andrea Pfannes und Konrad Huber</span>
          <br><br>
          "What is single malt? What makes a good quality whisky? Why is Scotland famous for the whiskies? We bombarded our
          “whisky teacher” Stuart with these and many other questions. While we were allowed to try a selection of different whiskies
          according to a certain ritual, Stuart introduced us to the secrets of Scottish whisky with all kinds of background stories. The
          memories of an unforgettable and wonderful evening remain."
          <span class="text-right">Sarah und Hans Hinterwimmer</span>
          <br><br>
          "Although I'm not a newbie, I learned a lot that evening. Stuart is an absolute specialist with an admirable knowledge of details
          and background. The evening was over too quickly and it will be a while before we will have tried all the whiskies from Stuart's
          collection. Thank you for the eventful and cheerful evening."
          <span class="text-right">Peter Wollenstätter</span>
    `;
    data.barrel = `
          The old barrel heads still displayed the distillery names, cask and batch numbers but were in quite poor condition. A
          ‘Lockdown Project’ for 2020 was to rejuvenate them with a new coat of paint and stencilled lettering. The new barrel heads
          can be displayed and make an excellent gift for whisky lovers. These can be ordered by sending an email to the Whisky
          Teacher. Prices vary according to size.
          <br><br>
          More information about the cooperage can be found at
          <a href="https://www.speysidecooperageky.com/" target="_blank" rel="nofollow">speysidecooperageky.com</a>
    `;
    this.$store.commit('setShowMoreData', data[block]);
  }
}
