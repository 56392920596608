







import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'WCookies',
})
export default class WCookies extends Vue {
  showAlert: boolean = false;

  mounted() {
    this.showAlert = !window.localStorage.getItem('cookiesAccepted');
  }

  handleClick(e: any) {
    this.showAlert = false;
    window.localStorage.setItem('cookiesAccepted', 'yes');
  }
}
