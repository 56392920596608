















import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'wRadio',
})
export default class Preloader extends Vue {
}
