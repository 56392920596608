import Vue from 'vue';

export const eventBus = new Vue();

export const activateBlockAnimation = (page: string, block: number | string) => eventBus.$emit(
  'activateAnimation',
  { page, block },
);

export const activateHalfBlockAnimation = (page: string, block: number, down: boolean) => eventBus.$emit(
  'activateHalfBlockAnimation',
  { page, block, down },
);
