






















































































































































































































import { Component, Vue } from 'vue-property-decorator';
import WButton from '@/components/Button.vue';
import AnimatedText from '@/components/AnimatedText.vue';
import ScrollDownIcon from '@/components/ScrollDownIcon.vue';
import { eventBus } from '@/services/eventBus';

@Component({
  name: 'About',
  components: {
    WButton,
    AnimatedText,
    ScrollDownIcon,
  },
})
export default class About extends Vue {
  slides: any[] = [
    {
      title: 'highland',
      image: 'region_highland_500.png',
      text: '<p>The Highlands are well known for more of the famous whiskies such as Tomatin  Dalmore or Glenmorangie. Generally, Malts from the Highlands tend to be fruity and sweet at the start with a dry finish. The Malts from the North Highlands are likely to be more influenced by the coastal locations and tend to be light bodied with a hint of saltiness, for example Clynelish.<br><br><span class="lg-only">The Highland whisky region stretches from the most Northerly point on the mainland, down to Stirlingshire, just North of Glasgow. There are currently 30 distilleries operating within theregion. They include Glenmorangie, Dalmore, Old Pulteney, Wolfburn, Glen Ord, Balblair, Tomatin and Clynelish in the North.In the West, distilleries include Oban, Ben Nevis and the newArdnamurchan. Over to the East you will find Ardmore, Glenglassaugh, Glendronach, Glen Garioch, Royal Lochnagar andFettercairn. In the South, Edradour, Blair Athol, Aberfeldy, Tullibardine, Deanston, Glengoyne and Loch Lomond are all partof the Highland distilleries along with Glenturret which isScotland\'s oldest distillery.</span></p>',
    },
    {
      title: 'islands',
      image: 'region_islands_500.png',
      text: '<p>The Islands region is more of a subdivision of the Highlands and is spread around the coast of Scotland. Given their location, the Malts are highly influenced by the sea and local soils. These whiskies have a peaty character to them but are still sweet and light bodied. Talisker Single Malt from the Isle of Skye is a classic Island region whisky<br><br><span class="lg-only">The Islands region is a sub-category of the Highlands. The islands are mainly situated on the West coast stretching North as far as Orkney. However, they exclude the island of Islay which is a region in itself. The Islands inlcude the most northern distillery, Highland Park as well as Scapa on the island of Orkney. Island distilleries include Talisker on the Isle of Skye and Tobermory (Ledaig) on the Island of Mull as well as Jura and Arran. Three new island distilleries include Abhainn Dearg on the Island of Lewis in the Outer Hebrides, The Isle of Raasay Distillery and Torabhaig distillery on the Isle of Skye.</span></p>',
    },
    {
      title: 'islay',
      image: 'region_islay_500.png',
      text: '<p>The island of Islay is susceptible to heavy rain and strong winds coming from the sea. The land is flat and mainly formed of peat. All of these elements have a strong influence on the flavours of the locally produced whiskies. Hence Islay Malts tend to have a peaty and sometimes medicinal palate, for example Laphroaig. The unpeated Bruichladdich however, has a salty and dry finish<br><br><span class="lg-only">Islay is know as the \'Queen of the Hebrides\' and is the most southern of these islands. It is well know for its many distilleries including Ardbeg, Bruichladdich, Bowmore, Bunnahabhain, Caol Ila, Kilchoman, Lagavulin and Laphroaig, which is one of Prince Charles\'s favourites and therefore it was awarded the Royal Warrant in 1994. More distillleries are planned for this region.</span></p>',
    },
    {
      title: 'lowland',
      image: 'region_lowland_500.png',
      text: '<p>The Lowlands region is the home of blended whisky. More whisky is produced here than in any other region due to the large scale production of grain whisky, however there are a few distilleries which continue to produce single malts. Lowland Malts tend to be unpeated and therefore are more mellow with a light, floral palate and sweet, fruity flavours. Lowland malts include Auchentoshan and Glenkinchie<br><br><span class="lg-only">The Lowlands are the most southern of the six regions from Fife, down through the central belt to Dumfries and Galloway and into the Scottish Borders. Although it produces mainly grain whisky, there are still a few remaining distilleries producing single malts, these include Ailsa Bay, Auchentoshan and Glenkinchie with Annandale reborn in 2014 and Bladnoch in 2015.</span></p>',
    },
    {
      title: 'speyside',
      image: 'region_speyside_500.png',
      text: '<p>Over half of Scotland’s distilleries are located in the Speyside region located within the Highlands. There is a huge variety including some rich sherry flavoured such as Balvenie 12 Double Wood, but also more complex light, floral whiskies such as Glenlivet. Generally, these whiskies are sweeter but may also have a peaty flavour with a trace of smoke<br><br><span class="lg-only">Speyside is a region in Morayshire also located within the Highlands. There are so many distilleries within this region, there are too many to list. Some of the more well known distilleries such as Glenlivet, Glenfiddich and Macallan account for one third of the entire Single Malt market in the world. Other well known distilleries include Aberlour, Cardhu, Balvenie and Dufftown (Singleton). You may also have heard of Braeval which ties with Dalwhinnie (Highlands) as the highest distillery in Scotland.</span></p>',
    },
    {
      title: 'campbeltown',
      image: 'region_campbeltown_500.png',
      text: '<p>Campbeltown whiskies are influenced by the elements in the same way as the Islay Malts. Salty, peaty and full bodied, warm with a depth of flavour and hints of spice. Springbank, Glen Scotia  and Glengyle are the only remaining distilleries in an area where once there were thirty four. Their warming effect make them good seasonal whiskies<br><br><span class="lg-only">Campbeltown is the main town on the remote and beautiful Kintyre Peninsula in west Argyll. Sandwiched between the isles of Islay and Arran, it was once a thriving whisky region with 34 distilleries in operation. Now only 3 distilleries remain active, Glengyle (Kilkerran), Glen Scotia and Springbank, which produces 3 distinctive single malts, including Longrow and Hazelburn.</span></p>',
    },
  ];
  sliderIndex: number = 7;

  isTouch: boolean = false;

  mounted() {
    this.$nextTick(async () => {
      if (this.$store.getters.isFirstPageLoaded) {
        eventBus.$emit('endPageTransition');
        eventBus.$on('pageTransitionEnded', this.onPageTransitionEnd);
      } else {
        await this.$store.dispatch('setFirstPageLoad');
        this.initPage();
      }
    });

    this.isTouch = 'ontouchstart' in window;

    // Listening eventBus
    eventBus.$on('activateAnimation', this.onAnimateBlock);
  }

  destroyed() {
    eventBus.$off('activateAnimation', this.onAnimateBlock);
  }

  // eslint-disable-next-line
  initPage() {
    eventBus.$emit('enableScroll');
  }

  onPageTransitionEnd() {
    this.initPage();
    eventBus.$off('pageTransitionEnded', this.onPageTransitionEnd);
  }

  setSlide(index: number) {
    this.sliderIndex = index;
  }

  // eslint-disable-next-line
  setImage(name: string) {
    // eslint-disable-next-line
    return require(`../assets/images/about/regions/${name}`);
  }

  onAnimateBlock(payload: any) {
    const { page, block } = payload;

    if (page === 'about') {
      if (typeof block === 'string') {
        // @ts-ignore
        this.$refs[block].classList.add('activated');
      }

      if (block === 'aboutRegionsMap') {
        this.sliderIndex = 0;
      }
    }
  }
}
