



















































































































































































import { Component, Vue } from 'vue-property-decorator';
import { waitFor, generateRandomClass } from '@/services/util';
import WButton from '@/components/Button.vue';
import Preloader from '@/components/Preloader.vue';
import wRadio from '@/components/Radio.vue';
import { sendMail } from '@/services/api';

@Component({
  components: {
    WButton,
    wRadio,
    Preloader,
  },
})
export default class wCorporateFrom extends Vue {
  formData: any = {
    package: {},
    name: '',
    email: '',
    message: '',
  };

  corporateQuestions: any = [
    {
      question: 'What is the nature of event?',
      answer: '',
    },
    {
      question: 'What is the overall budget of event?',
      answer: '',
    },
    {
      question: 'How many guests will participate in the event?',
      answer: '',
    },
    {
      question: 'Would a pre-tasting be required?',
      answer: '',
    },
  ];

  submitting: boolean = false;
  error: boolean = false;
  step: number = 0;
  type: string = '';

  formSubmitted: boolean = false;
  corporateFormSent: boolean = false;
  formWithError: boolean = false;
  formSpecificClass: string = '';

  showForm: boolean = false;

  timeout: any;

  goToNextStep(key: string, value: string) {
    if (key) {
      if (key === 'type') {
        this.type = value;
      }
      this.formData.package[key] = value;
    }
    this.step += 1;
  }

  goBack() {
    this.step -= 1;
  }

  setRadioValue() {
    this.step += 1;
  }

  onFocus() {
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
    } else {
      this.$store.dispatch('setInputStatus', true);
    }
  }

  onBlur() {
    this.timeout = setTimeout(() => {
      this.$store.dispatch('setInputStatus', false);
      this.timeout = null;
    }, 250);
  }

  // eslint-disable-next-line
  onShow() {
    const form = document.querySelector('.corporate-form .form-wrapper');
    if (form) {
      form.classList.add('shown');
    }
  }

  async sendForm(e: any) {
    if (e) {
      e.preventDefault();
    }

    this.formSubmitted = true;

    if (!this.formData.name || !this.formData.email) {
      return;
    }

    let mailHTML: string = '';
    let name: string = '';

    name = 'Corporate Journey';
    this.corporateQuestions.forEach((question: any) => {
      mailHTML += `<div>${question.question}</div><div style="margin-bottom: 15px;">${question.answer}</div>`;
    });

    this.step += 1;
    this.submitting = true;

    let error: boolean = false;
    try {
      await sendMail({
        email: this.formData.email,
        name: this.formData.name,
        subject: `${name} package requested`,
        message: this.formData.message,
        info: mailHTML,
      });
    } catch (err) {
      error = true;
    }

    if (!error) {
      await waitFor(1000);

      this.submitting = false;
    } else {
      this.error = true;
    }
  }

  submitCorporateForm() {
    let hasError: boolean = false;
    this.corporateFormSent = true;

    this.corporateQuestions.forEach((question: any) => {
      if (!question.answer) {
        hasError = true;
      }
    });

    if (!hasError) {
      this.step += 1;
    }
  }
}
