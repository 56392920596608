






import { Component, Vue } from 'vue-property-decorator';
import { generateRandomClass } from '@/services/util';

@Component({
  name: 'AnimatedText',
})
export default class AnimatedText extends Vue {
  currentClass: string = '';

  mounted() {
    let elementHtml = this.$el.innerHTML;
    let elementContent;
    if (this.$el.textContent) {
      elementContent = this.$el.textContent.trim().split(' ');
    }

    const textChunks: string[] = [];
    this.currentClass = generateRandomClass('at');
    if (elementContent) {
      elementContent.forEach((element) => {
        const textChunk = element.replace('\n', '');
        if (textChunk) {
          textChunks.push(textChunk);
        }
      });
    }

    const changedChunks: string[] = [];
    textChunks.forEach((chunk) => {
      const regex = new RegExp(`${chunk}`, 'g');
      if (!changedChunks.includes(chunk)) {
        elementHtml = elementHtml.replace(regex, (match, offset) => {
          if (
            (
              elementHtml.charAt(offset - 1) === '>'
              || elementHtml.charAt(offset - 1) === ' '
            )
            && (
              elementHtml.charAt(offset + match.length) === ' '
              || elementHtml.charAt(offset + match.length) === '\n'
              || elementHtml.charAt(offset + match.length) === '<'
            )
          ) {
            return `<span class="${this.currentClass}">${match}</span>`;
          }
          return match;
        });
        changedChunks.push(chunk);
      }
    });


    this.$el.innerHTML = elementHtml;
  }

  onShow() {
    const spanChunks = (document.querySelectorAll(`.${this.currentClass}`));
    const spanAuthorChunks = document.querySelectorAll(`.w-quote-author > .${this.currentClass}`);
    let authorElement: HTMLElement;
    if (spanAuthorChunks.length && spanAuthorChunks[0].parentElement) {
      authorElement = spanAuthorChunks[0].parentElement;
    }
    const SHOW_INTERVSL = 150;
    let authorElementAnimationAdded = false;

    spanChunks.forEach((span, index) => {
      if (span.parentElement && !span.parentElement.classList.contains('w-quote-author')) {
        setTimeout(() => {
          span.classList.add('shown');
        }, SHOW_INTERVSL * (index + 1));
      } else if (!authorElementAnimationAdded && authorElement) {
        authorElementAnimationAdded = true;
        setTimeout(() => {
          authorElement.classList.add('shown');
        }, SHOW_INTERVSL * (index + 3));
      }
    });
  }
}
