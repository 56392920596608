










































import { Component, Vue, Watch } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import Menu from '@/components/Menu.vue';
import {
  activateBlockAnimation,
  eventBus,
  activateHalfBlockAnimation,
} from '@/services/eventBus';

@Component({
  name: 'ScrollBlock',
  computed: {
    ...mapGetters(['isScrollEnabled', 'isMenuOpen']),
  },
  components: {
    Menu,
  },
})
export default class ScrollBlock extends Vue {
  $refs: any;
  scrollHeight: number = 0;
  currentScroll: number = 0;
  scrollStyle: any = {};
  pageName: string = '';
  smoothScrollActive: boolean = false;
  currentBlockNumber: number = 0;
  previousWidth: number = 0;
  screenDimensions: number[] = [];
  changedOrientation: boolean = false;

  // scrollbar vars
  SCROLLBAR_HEIGHT: number = 200;
  scrollBarDistance: number = 0;
  dragStartPosition: number = 0;
  dragStartCursorYPosition: number = 0;
  scrollBarPosition: number = 0;
  scrollBarStyle: any = {};

  // touch scroll settings
  isTouch: boolean = false;
  previousTouch: any;
  firstTouch: any;
  swipeDeltaY: number = 0;
  startDeltaY: number = 0;
  startScrollPosition: number = 0;

  // blockAnimation
  blocksLength: number = 0;
  activatedBlockAnimations: number[] = [];
  activatedHalfBlockAnimations: number[] = [];

  specificPoints: any[] = [];
  aboutPageSpecificPointsId: string[] = [
    'aboutAboutMe',
    'aboutAims',
    'aboutWhisky',
    'aboutRegions',
    'aboutRegionsMap',
  ];

  mounted() {
    this.configureDimensions();
    this.isTouch = 'ontouchstart' in window;
    this.previousWidth = window.innerWidth;

    if (this.$route.name) {
      this.pageName = this.$route.name;
    }
    if (this.$route.name === 'about') {
      this.setAboutPageAnimationPoints();
    }

    // configure blocks animation settings
    this.blocksLength = document.getElementsByClassName('w-block').length;

    if (this.isTouch) {
      // window.addEventListener('scroll', this.onScroll, { passive: false });
      window.addEventListener('touchstart', this.onTouchStart, { passive: false });
      window.addEventListener('touchend', this.onTouchEnd, { passive: false });
      window.addEventListener('touchmove', this.onTouchMove, { passive: false });
    } else {
      window.addEventListener('wheel', this.onWheel, { passive: false });
    }
    window.addEventListener('resize', this.onResize);

    // Set listeners for events from child components
    eventBus.$on('enableScroll', this.onFinishStartAnimation);
    // eventBus.$on('pageChanged', this.onPageChanged);
    eventBus.$on('startPageTransition', this.onStartPageTransition);
    eventBus.$on('endPageTransition', this.onEndPageTransition);
    eventBus.$on('scrollTo', (scrollTo: number) => {
      this.smoothScrollTo(scrollTo);
    });
  }

  smoothScrollTo(scrollTo: number, isSwipe: boolean = false) {
    let scrollDirection: number;
    let easingFunction = 'ease-in-out';
    const diff = scrollTo - this.currentScroll;
    // const scrollOnStart: number = this.currentScroll;

    if (isSwipe) {
      easingFunction = 'ease-out';
    }

    this.smoothScrollActive = true;

    if (diff > 0) {
      scrollDirection = 1;
    } else if (diff < 0) {
      scrollDirection = -1;
    } else {
      return;
    }
    this.$set(
      this.scrollStyle,
      'transition',
      `transform 1s ${easingFunction}`,
    );
    this.$set(
      this.scrollBarStyle,
      'transition',
      `transform 1s ${easingFunction}`,
    );

    this.scrollTo(scrollDirection, scrollTo, false, true);

    const onTransitionEnd = (e: any) => {
      if (e.target && e.target.id === 'scrollContent') {
        this.$set(
          this.scrollBarStyle,
          'transition',
          'none',
        );
        this.$set(
          this.scrollStyle,
          'transition',
          'none',
        );
        this.smoothScrollActive = false;

        // set block animations
        const blockPosition: number = scrollTo / window.innerHeight;
        this.currentBlockNumber = Math.floor(blockPosition);

        for (let i = this.activatedBlockAnimations.length; i < this.currentBlockNumber; i += 1) {
          this.activatedBlockAnimations.push(i + 1);
          activateBlockAnimation(this.pageName, i + 1);
        }

        if (this.currentScroll === window.innerHeight * this.currentBlockNumber) {
          for (let i = this.activatedHalfBlockAnimations.length; i < this.currentBlockNumber; i += 1) {
            this.activatedHalfBlockAnimations.push(i + 1);
            activateHalfBlockAnimation(this.pageName, i, true);
          }
        }

        if (this.specificPoints.length) {
          const indexesToRemove: number[] = [];
          this.specificPoints.forEach((point: number, index: number) => {
            if (this.currentScroll + window.innerHeight > point) {
              activateBlockAnimation(this.pageName, this.aboutPageSpecificPointsId[index]);
              indexesToRemove.push(index);
            }
          });
          indexesToRemove.forEach((index: number) => {
            this.specificPoints.splice(0, 1);
            this.aboutPageSpecificPointsId.splice(0, 1);
          });
        }

        this.$refs.scrollContent.removeEventListener('transitionend', onTransitionEnd, true);
      }
    };

    this.$refs.scrollContent.addEventListener('transitionend', onTransitionEnd, true);
  }

  scrollTo(scrollDirection: number, scrollTo: number, isScrollBar: boolean = false, isSmoothScrolling: boolean = false) {
    if (this.smoothScrollActive && !isSmoothScrolling) {
      return;
    }

    if (isScrollBar) {
      const oldScrollPosition = this.currentScroll;
      this.scrollBarPosition = scrollTo;
      this.currentScroll = this.scrollHeight * this.scrollBarPosition / this.scrollBarDistance;
      // eslint-disable-next-line
      scrollDirection = this.currentScroll - oldScrollPosition;
    } else {
      this.currentScroll = scrollTo;
      this.scrollBarPosition = this.scrollBarDistance * this.currentScroll / this.scrollHeight;
    }

    // Animation settings
    const blockPosition = this.currentScroll / window.innerHeight;
    this.currentBlockNumber = Math.floor(blockPosition);
    if (scrollDirection > 0) {
      if (blockPosition - this.currentBlockNumber > 0.5) {
        if (this.currentBlockNumber + 1 > this.activatedBlockAnimations.length) {
          this.activatedBlockAnimations.push(this.currentBlockNumber + 1);
          activateBlockAnimation(this.pageName, this.currentBlockNumber + 1);
        }
        if (this.currentBlockNumber + 1 > this.activatedHalfBlockAnimations.length) {
          this.activatedHalfBlockAnimations.push(this.currentBlockNumber + 1);
          activateHalfBlockAnimation(this.pageName, this.currentBlockNumber, true);
        }
      }
      if (!this.isTouch && this.specificPoints.length) {
        const indexesToRemove: number[] = [];
        this.specificPoints.forEach((point: number, index: number) => {
          if (this.currentScroll + window.innerHeight > point) {
            activateBlockAnimation(this.pageName, this.aboutPageSpecificPointsId[index]);
            indexesToRemove.push(index);
          }
        });
        indexesToRemove.forEach((index: number) => {
          this.specificPoints.splice(0, 1);
          this.aboutPageSpecificPointsId.splice(0, 1);
        });
      }
    } else if (
      blockPosition - Math.floor(blockPosition) < 0.5
      // TODO: Fix multiple halfblock animation invocation
      // && currentBlockNumber === this.activatedHalfBlockAnimations.length
    ) {
      activateHalfBlockAnimation(this.pageName, this.currentBlockNumber, false);
      this.activatedHalfBlockAnimations.splice(
        this.activatedHalfBlockAnimations.length - 1,
        1,
      );
    }

    if (this.currentScroll < 0) {
      this.currentScroll = 0;
      this.scrollBarPosition = 0;
    } else if (this.currentScroll > this.scrollHeight) {
      this.currentScroll = this.scrollHeight;
      this.scrollBarPosition = this.scrollBarDistance;
    }

    this.$set(
      this.scrollBarStyle,
      'transform',
      `translate3d(0, ${this.scrollBarPosition}px, 0)`,
    );

    this.$set(
      this.scrollStyle,
      'transform',
      `translate3d(0, ${this.currentScroll * -1}px, 0)`,
    );

    if (!isScrollBar) {
      this.dragStartPosition = this.scrollBarPosition;
    }
  }

  onScroll(e: any) {
    if (this.$store.getters.isScrollEnabled) {
      // this.scrollTo(e.deltaY, this.currentScroll + e.deltaY);
    } else {
      e.preventDefault();
    }
  }

  onWheel(e: any) {
    e.preventDefault();

    if (this.$store.getters.isScrollEnabled) {
      this.scrollTo(e.deltaY, this.currentScroll + e.deltaY);
    }
  }


  onTouchStart(e: any) {
    const touch = e.changedTouches[0];
    this.previousTouch = {
      x: touch.pageX,
      y: touch.pageY,
    };
    this.firstTouch = {
      x: touch.pageX,
      y: touch.pageY,
    };
    this.startScrollPosition = this.currentScroll;
    this.startDeltaY = touch.pageY;
  }

  onTouchEnd(e: any) {
    const touch = e.changedTouches[0];

    const distanceY = this.firstTouch.y - touch.pageY;
    const distanceX = this.firstTouch.x - touch.pageX;

    if (
      Math.abs(distanceY) > 5
      && (Math.abs(distanceY) > Math.abs(distanceX))
    ) {
      if (this.$store.getters.isScrollEnabled) {
        let scrollTo: number;
        if (this.startDeltaY - touch.pageY > 0) {
          scrollTo = (Math.floor(this.currentScroll / window.innerHeight) + 1) * window.innerHeight;
          this.smoothScrollTo(scrollTo, true);
        } else if (this.startDeltaY - touch.pageY < 0) {
          scrollTo = (Math.ceil(this.currentScroll / window.innerHeight) - 1) * window.innerHeight;
          this.smoothScrollTo(scrollTo, true);
        }
      }
    } else if (Math.abs(distanceY) < Math.abs(distanceX)) {
      this.smoothScrollTo(this.startScrollPosition, true);
    }

    this.previousTouch = null;
    this.firstTouch = null;
    this.swipeDeltaY = 0;
    this.startDeltaY = 0;
    this.startScrollPosition = 0;
  }

  onTouchMove(e: any) {
    e.preventDefault();

    const touch = e.changedTouches[0];
    this.swipeDeltaY = this.previousTouch.y - touch.pageY;


    if (this.$store.getters.isScrollEnabled) {
      this.scrollTo(this.swipeDeltaY, this.currentScroll + this.swipeDeltaY);
    }

    this.previousTouch = {
      x: touch.pageX,
      y: touch.pageY,
    };
  }

  configureDimensions() {
    if (!this.screenDimensions.length) {
      this.screenDimensions.push(window.innerWidth);
      this.screenDimensions.push(window.innerHeight);
      this.screenDimensions.push(window.screen.width);
      this.screenDimensions.push(window.screen.height);
    } else if (
      this.screenDimensions.includes(window.innerWidth)
      && this.screenDimensions.includes(window.innerHeight)
    ) {
      this.changedOrientation = true;
    }
    if (
      !this.isTouch
      || (
        this.isTouch
        && this.changedOrientation
      )
    ) {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);

      this.$nextTick(() => {
        this.scrollBarDistance = window.innerHeight - this.SCROLLBAR_HEIGHT;
        this.scrollHeight = this.$refs.scrollContent.clientHeight - window.innerHeight;
      });
    }
  }

  initScrollBar(e: any) {
    document.onmousemove = this.onMouseMove;
    document.onmouseup = this.onMouseUp;

    // Prevent text selecting
    this.$set(this.scrollStyle, 'userSelect', 'none');

    // Set drag start position
    this.dragStartCursorYPosition = e.clientY;
  }

  onFinishStartAnimation() {
    this.$store.commit('setScrollStatus', true);
  }

  onMouseUp() {
    document.onmousemove = null;
    document.onmouseup = null;
    this.$set(this.scrollStyle, 'userSelect', null);

    this.dragStartPosition = this.scrollBarPosition;
  }

  onMouseMove(e: any) {
    const scrollTo = this.dragStartPosition + e.clientY - this.dragStartCursorYPosition;

    this.scrollTo(e.clientY, scrollTo, true);
  }

  onResize() {
    this.configureDimensions();

    if (this.currentScroll > this.scrollHeight) {
      this.currentScroll = this.scrollHeight;
      this.scrollStyle = {
        transform: `translate3d(0, ${this.currentScroll * -1}px, 0)`,
      };
    }

    if (
      this.isTouch
      && this.changedOrientation
    ) {
      let activeBlockNumber: number = this.currentBlockNumber;
      if (window.innerWidth >= 768 && this.previousWidth < 768) {
        activeBlockNumber = Math.ceil(activeBlockNumber / 2);

        if (this.activatedBlockAnimations.length !== 5) {
          const newActivatedBlocksLenght = Math.ceil(this.activatedBlockAnimations.length / 2);

          while (this.activatedBlockAnimations.length !== newActivatedBlocksLenght) {
            this.activatedBlockAnimations.splice(this.activatedBlockAnimations.length - 1, 1);
          }
        }
      } else if (window.innerWidth < 768 && this.previousWidth >= 768) {
        activeBlockNumber = activeBlockNumber * 2 - 1;
      }
      const scrollTo = activeBlockNumber * window.innerHeight;
      this.scrollTo(this.currentScroll - scrollTo, scrollTo);

      this.previousWidth = window.innerWidth;
    }
    this.changedOrientation = false;
  }

  onPageChanged() {
    this.configureDimensions();
    this.blocksLength = document.getElementsByClassName('w-block').length;
    this.activatedBlockAnimations = [];
    this.activatedHalfBlockAnimations = [];
    this.scrollTo(1, 0);
  }

  onStartPageTransition() {
    this.$store.commit('setScrollStatus', false);
    this.$refs.transitionBlock.classList.add('shown');
    this.$refs.slogan.classList.add('active-show');
    this.$nextTick(() => {
      this.$refs.slogan.classList.add('shown');
    });
  }

  onEndPageTransition() {
    this.configureDimensions();
    this.blocksLength = document.getElementsByClassName('w-block').length;
    this.activatedBlockAnimations = [];
    this.activatedHalfBlockAnimations = [];
    this.scrollTo(1, 0);
    this.$refs.transitionBlock.classList.add('hidden');

    // const transitionBlock = document.getElementById('pageTransitionBlock');

    const onTranitionEnd = () => {
      this.$refs.transitionBlock.classList.remove('shown', 'hidden');
      this.$refs.slogan.classList.remove('shown');
      setTimeout(() => {
        this.$refs.slogan.classList.remove('active-show');
      }, 500);
      this.$refs.transitionBlock.removeEventListener('transitionend', onTranitionEnd, true);
      eventBus.$emit('pageTransitionEnded');
    };

    this.$refs.transitionBlock.addEventListener('transitionend', onTranitionEnd, true);
  }

  setAboutPageAnimationPoints() {
    this.specificPoints = [];
    this.aboutPageSpecificPointsId.forEach((id: string, index: number) => {
      if (this.isTouch) {
        // regions point already set on the previous step
        if (index === this.aboutPageSpecificPointsId.length - 1) return;
        this.specificPoints.push(window.innerHeight * (index + 1));
        if (index === this.aboutPageSpecificPointsId.length - 2) {
          // show regions at the same time with title
          this.specificPoints.push(window.innerHeight * (index + 1));
        }
      } else if (id === 'aboutRegions') {
        // @ts-ignore
        this.specificPoints.push(document.getElementById(id).offsetTop + 50);
      } else {
        // @ts-ignore
        this.specificPoints.push(document.getElementById(id).offsetTop + (document.getElementById(id).clientHeight / 2));
      }
    });
  }

  @Watch('$route')
  onRouteChange() {
    if (this.$route.name) {
      this.pageName = this.$route.name;
    }

    if (this.$route.name === 'about') {
      this.aboutPageSpecificPointsId = [
        'aboutAboutMe',
        'aboutAims',
        'aboutWhisky',
        'aboutRegions',
        'aboutRegionsMap',
      ];
      this.$nextTick(() => {
        this.setAboutPageAnimationPoints();
      });
    }
  }
}

