













































































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { waitFor, generateRandomClass } from '@/services/util';
import wRadio from '@/components/Radio.vue';
import Map from '@/components/Map.vue';
import WButton from '@/components/Button.vue';
import Preloader from '@/components/Preloader.vue';
import { sendMail } from '@/services/api';

@Component({
  components: {
    wRadio,
    Map,
    WButton,
    Preloader,
  },
})
export default class wPackageForm extends Vue {
  @Prop({ required: true })
  name: string;
  @Prop({ required: true })
  formItems: any;

  formData: any = {
    name: '',
    email: '',
    message: '',
  };

  formSubmitted: boolean = false;
  submitting: boolean = false;
  error: boolean = false;
  step: number = 1;

  formWithError: boolean = false;
  formSpecificClass: string = '';

  timeout: any;

  mounted() {
    this.formSpecificClass = generateRandomClass('pf');
  }

  onFocus() {
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
    } else {
      this.$store.dispatch('setInputStatus', true);
    }
  }

  onBlur() {
    this.timeout = setTimeout(() => {
      this.$store.dispatch('setInputStatus', false);
      this.timeout = null;
    }, 250);
  }

  setRadioValue(value: any, itemIndex: number) {
    this.$set(this.formItems[itemIndex], 'value', value);
  }

  async sendForm(e: any) {
    e.preventDefault();

    if (this.step === 1) {
      if (!this.checkForm()) {
        this.formWithError = true;
      } else {
        this.step = 2;
      }
    } else if (this.step === 2) {
      this.step = 3;
    } else if (this.step === 3) {
      this.formSubmitted = true;

      if (!this.formData.name || !this.formData.email) {
        return;
      }

      let mailHTML = '';
      this.formItems.forEach((item: any) => {
        mailHTML += `<div><span style="padding-right: 15px;text-transform: capitalize;">${item.name}</span>${item.value}</div>`;
      });

      this.submitting = true;
      this.step = 4;

      let error: boolean = false;
      try {
        await sendMail({
          email: this.formData.email,
          name: this.formData.name,
          subject: `${this.name.charAt(0).toUpperCase() + this.name.slice(1)} package requested`,
          message: this.formData.message,
          info: mailHTML,
        });
      } catch (err) {
        error = true;
      }

      if (!error) {
        await waitFor(1000);

        this.submitting = false;
      } else {
        this.error = true;
      }
    }
  }

  checkForm() {
    let isValid: boolean = true;
    this.formItems.forEach((item: any) => {
      if (!item.value) {
        isValid = false;
      }
    });
    return isValid;
  }

  onShow() {
    const items: any = document.querySelectorAll(`.${this.formSpecificClass} .package-item`);
    const button: any = document.querySelector(`.${this.formSpecificClass} .package-action`);
    const SHOW_INTERVAL: number = 150;

    items.forEach((item: any, index: number) => {
      setTimeout(() => {
        item.classList.add('shown');
      }, SHOW_INTERVAL * index);

      if (index === items.length - 1) {
        setTimeout(() => {
          button.classList.add('shown');
        }, SHOW_INTERVAL * index + 500);
      }
    });
  }

  // eslint-disable-next-line
  setMapImage(name: string) {
    // eslint-disable-next-line
    return require(`../assets/images/packages/maps/map_${name}.png`);
  }

  get showMobileError() {
    let isValid: boolean = false;
    if (this.formWithError) {
      this.formItems.forEach((item: any) => {
        if (!item.value) {
          isValid = true;
        }
      });
    }
    return isValid;
  }
}
