













import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'WButton',
})
export default class WButton extends Vue {
  @Prop() private text!: string;
  @Prop() private href?: string;

  handleClick(e: any) {
    this.$emit('click', e);
  }
}
