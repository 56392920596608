









































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { eventBus } from '@/services/eventBus';
import wRadio from '@/components/Radio.vue';
import WButton from '@/components/Button.vue';
import Preloader from '@/components/Preloader.vue';
import { sendMail } from '@/services/api';
import { waitFor } from '@/services/util';

@Component({
  name: 'Contacts',
  components: {
    wRadio,
    WButton,
    Preloader,
  },
})
export default class Contacts extends Vue {
  formSent: boolean = false;
  formData: any = {
    name: '',
    email: '',
    phone: '',
    enquire: '',
  };

  formSubmitted: boolean = false;
  submitting: boolean = false;
  error: boolean = false;

  timeout: any;

  isTouch: boolean = false;

  mounted() {
    this.$nextTick(async () => {
      if (this.$store.getters.isFirstPageLoaded) {
        eventBus.$emit('endPageTransition');
        eventBus.$on('pageTransitionEnded', this.onPageTransitionEnd);
      } else {
        await this.$store.dispatch('setFirstPageLoad');
        this.initPage();
      }
    });

    this.isTouch = 'ontouchstart' in window;
  }

  onFocus() {
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
    } else {
      this.$store.dispatch('setInputStatus', true);
    }
  }

  onBlur() {
    this.timeout = setTimeout(() => {
      this.$store.dispatch('setInputStatus', false);
      this.timeout = null;
    }, 250);
  }

  async sendForm(e: any) {
    e.preventDefault();

    this.formSubmitted = true;

    if (!this.formData.name || !this.formData.email) {
      return;
    }

    this.submitting = true;
    this.formSent = true;

    let error: boolean = false;
    try {
      await sendMail({
        email: this.formData.email,
        name: this.formData.name,
        subject: 'Enquire requested',
        message: this.formData.enquire,
      });
    } catch (err) {
      error = true;
    }

    if (!error) {
      await waitFor(1000);

      this.submitting = false;
    } else {
      this.error = true;
    }
  }

  // eslint-disable-next-line
  initPage() {
    eventBus.$emit('enableScroll');
  }

  onPageTransitionEnd() {
    this.initPage();
    eventBus.$off('pageTransitionEnded', this.onPageTransitionEnd);
  }
}
